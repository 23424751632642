import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

// Set up Apollo Client
export const Client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: true,
    resultCaching: true,
    typePolicies: {
      Artifact: {
        keyFields: ['catalogNumber'],
      },
    },
  }),
  link: new HttpLink({
    fetch,
    // Temporary GRAPHQL_URL
    // this should move to a URL and also be called out as an env var
    // but for this quick and dirty demo, this is acceptable.
    uri: 'https://api.smm.org/graphql',
  }),
  query: {
    fetchPolicy: 'cache-first',
  },
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
});

export default Client;
