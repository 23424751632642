exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collections-objects-search-index-js": () => import("./../../../src/pages/collections/objects/search/index.js" /* webpackChunkName: "component---src-pages-collections-objects-search-index-js" */),
  "component---src-pages-collections-objects-search-suggesting-search-box-index-js": () => import("./../../../src/pages/collections/objects/search/SuggestingSearchBox/index.js" /* webpackChunkName: "component---src-pages-collections-objects-search-suggesting-search-box-index-js" */),
  "component---src-pages-exhibits-experiences-race-say-their-names-js": () => import("./../../../src/pages/exhibits-experiences/race/say-their-names.js" /* webpackChunkName: "component---src-pages-exhibits-experiences-race-say-their-names-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-museum-from-home-index-js": () => import("./../../../src/pages/museum-from-home/index.js" /* webpackChunkName: "component---src-pages-museum-from-home-index-js" */),
  "component---src-pages-renew-js": () => import("./../../../src/pages/renew.js" /* webpackChunkName: "component---src-pages-renew-js" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-collections-object-index-js": () => import("./../../../src/templates/CollectionsObject/index.js" /* webpackChunkName: "component---src-templates-collections-object-index-js" */),
  "component---src-templates-landing-page-presentation-index-js": () => import("./../../../src/templates/LandingPage/Presentation/index.js" /* webpackChunkName: "component---src-templates-landing-page-presentation-index-js" */),
  "component---src-templates-landing-page-presentation-list-index-js": () => import("./../../../src/templates/LandingPage/PresentationList/index.js" /* webpackChunkName: "component---src-templates-landing-page-presentation-list-index-js" */),
  "component---src-templates-landing-page-sections-index-js": () => import("./../../../src/templates/LandingPage/Sections/index.js" /* webpackChunkName: "component---src-templates-landing-page-sections-index-js" */)
}

