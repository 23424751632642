//
// Set browser context for Gatsby pages
//
// Set site wide context like cookies and other items defines in the GlobalContextProvider
// and wrap our application inside of an Apollo client
//
// This context is identical to the context defined in gatsby-ssr.js as recommended by
// the Gatsby documentation on the browser APIs.
//

/* eslint-disable import/prefer-default-export */
// Don't require a default export. Gatsby's API can't support it here.

import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import GlobalContextProvider from '@context';
import ApolloClient from '@utils/apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@styles/base.scss';

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <ApolloProvider client={ApolloClient}>
      {element}
    </ApolloProvider>
  </GlobalContextProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
