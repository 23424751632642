// reducer.js
// This file contains our helpers & setup for Global Context state managment
// There is a getter/setter for persisting user state in local storage,
// an initial state object, and a reducer function to update that state object

// Getter for Object from LocalStorage
export const GetLocalStorageObject = (key) => {
  if (typeof window !== 'undefined') {
    const storageObj = JSON.parse(window.localStorage.getItem(key));
    if (storageObj && storageObj[0]) return storageObj[0];
  }

  return null;
};

// Setter for Object to LocalStorage
export const SetLocalStorageObject = (key, obj) => {
  if (typeof window !== 'undefined') window.localStorage.setItem(key, JSON.stringify(obj));
};

export const initialState = {
  collections: (typeof window !== 'undefined' && GetLocalStorageObject('smm-context')) || {
    lastQueryInput: '',
    paginationLimit: 10,
    view: 'list',
  },
  isMobileNavOpen: false,
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_COLLECTIONS_STATE': {
      const collectionsState = {
        ...state.collections,
        [action.payload.key]: action.payload.value,
      };

      const newState = {
        ...state,
        collections: collectionsState,
      };

      SetLocalStorageObject('smm-context', newState);
      return newState;
    }
    case 'SET_MOBILE_NAV_STATE': {
      return {
        ...state,
        isMobileNavOpen: action.payload,
      };
    }
    default: {
      throw new Error('Bad Action Type');
    }
  }
};

export default Reducer;
